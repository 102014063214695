var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1983c45f226104101f0086cfd950354717fa82f7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || 'https://451009e449844b8c9132248b5320e64f@o1098840.ingest.sentry.io/6123260',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.05,
    defaultIntegrations: false,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
        /network error/i,
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        'ResizeObserver is not defined',
        "Can't find variable: ResizeObserver",
        /audio/g,
        /closed.ogg/g,
        /new_paymeng.ogg/g,
        /The request is not allowed by the user /g,
        /possibly because the user denied permission/g,
    ],
});
